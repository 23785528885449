body .game_swiper {
  overflow: visible;

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 10vw;
    top: 0;
    z-index: 2;
  }

  &:before {
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    left: 0;
  }

  &:after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    right: 0;
  }

  &:hover {

    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.5;
      // background: rgba(0, 0, 0, 0.5);
      // color: rgba(255, 255, 255, 0.80);
    }
  }
}

.games {
  position: relative;
  padding-bottom: 120px;
  padding-top: 120px;
  width: 100vw !important;
  overflow: hidden;
  max-width: 100vw !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .home_title {
    position: relative;
    z-index: 3;
  }

  &::after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 20%;
    z-index: 2;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }
}

.game_swiper_wrap {
  position: relative;
  margin-top: 64px;
}

.game_swiper_img_big {
  position: absolute;
  bottom: 0;
  top: 0;
  display: block;
  width: 100vw;
  opacity: 0.5;
  filter: blur(100px);
}

.game_swiper_item {
  position: relative;
  z-index: 2;
  border-radius: 8px;
  background-color: #201C26;
  padding: 10px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .game_swiper_bottom .game_swiper_bottom_btn {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .game_swiper_img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  .game_swiper_bottom {
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .game_swiper_bottom_avatar {
      display: block;
      width: 60px;
      margin-right: 16px;
    }

    .game_swiper_bottom_fr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      flex: 1;
    }

    .game_swiper_bottom_inner {
      flex: 1;
      padding-right: 30px;
      width: 50%;
    }

    .game_swiper_bottom_btn {
      width: 190px;
      flex: 1;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }

    .game_swiper_bottom_title {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      padding-bottom: 8px;
      height: 27px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .game_swiper_bottom_content {
      font-size: 14px;
      font-weight: 400;
      opacity: 0.5;
      height: 21px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 768px) {
  .games {
    padding-top: 100px;
    padding-bottom: 100px;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    &::after {
      content: "";
      height: 10%;
    }
  }

  .game_swiper_wrap {
    margin-top: 24px;
  }

  .game_swiper_img_big {
    width: 170vw;
  }

  .game_swiper_item {
    .game_swiper_bottom {
      padding: 26px 0;

      .game_swiper_bottom_inner {
        display: flex;
        width: 100%;
        padding-right: 0;
      }

      .game_swiper_bottom_inner_inner {
        flex: 1;
        width: 70%;
      }

      .game_swiper_bottom_fr {
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
      }

      .btn {
        width: 100%;

        .game_swiper_bottom_btn {
          width: 100%;
        }
      }
    }
  }
}