.agent_stack_wrap {
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(115, 94, 148, 0.2) 30%,
      rgba(5, 1, 14, 0) 100%);
  padding: 1px 1px 0 1px;
  width: 100%;
  margin: 0 auto;
  transform-origin: center;

  .agent_stack_inner {
    border-radius: 16px 16px 0 0;
    background: radial-gradient(circle at 30% 10%, #231D2D, #05010E 80%);
    padding: 40px;

    display: flex;
    justify-content: space-between;
    gap: 20px;


    .agent_stack_inner_col {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      img {
        width: 34px;
        height: 34px;
      }
    }

    .agent_stack_inner_border_col1 {
      height: 160px;
      max-width: 293px;
    }

    &_fl {
      flex: 1;
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;

      img {
        width: 48px;
        height: 48px;
      }

      &_fl {
        flex: 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

      }

      &_fr {
        flex: 1;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .agent_stack_inner_border {
          height: 70px;
        }
      }
    }

    &_fr {
      width: 293px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      >.agent_stack_inner_border {
        flex: 1;

        h3 {
          font-weight: 900;
          font-family: "Poppins";
          font-size: 30px;
          background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 225, 225, 0.4) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          margin: 0;
          filter: drop-shadow(0 1px rgba(255, 255, 255, 0.5)); // 从上方打光的立体效果
        }
      }
    }

    &_border {
      background: rgba(255, 255, 255, 0.14);
      border-radius: 8px;
      text-align: center;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      box-shadow: 0 0 0 1px rgba(255, 225, 225, 0.1) inset;
      transition: all 0.3s ease;
      position: relative;
      padding: 1px;
      cursor: pointer;

      &::after {
        content: "";
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 1px;
        background: linear-gradient(180deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0) 100%);
        transition: all 0.3s ease;
        opacity: 0;
      }

      &:hover {

        &::after {
          content: "";
          opacity: 0.24;
        }

      }

      p {
        margin: 0;
        font-weight: 600;
        font-family: "Poppins";
        font-size: 20px;
        color: #F1F1F1
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .agent_stack_wrap {
    .agent_stack_inner {
      padding: 20px;

      .agent_stack_inner_col {
        gap: 4px;

      }

      .agent_stack_inner_border_col1 {
        height: 95px;
      }

      .agent_stack_inner_fl {

        .agent_stack_inner_fl_fl {
          gap: 8px;
        }

        img {
          width: 30px;
          height: 30px;
        }
      }

      .agent_stack_inner_fr {
        width: 100px;

        &>.agent_stack_inner_border {
          h3 {
            font-size: 14px;
          }
        }
      }

      .agent_stack_inner_border {
        p {
          font-size: 12px;
        }
      }
    }
  }
}