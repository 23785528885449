.backer_section_inner {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  img {
    display: block;
    flex: 1;
    width: 25%;
  }
}

@media screen and (max-width: 768px) {
  .backer_section_inner {
    img {
      width: 33%;
    }
  }
}